import React from "react";

const VanRental = () => {
  const sections = [
    {
      title: "Tempo Traveller Rental Pondicherry",
      content: (
        <>
          <p>
            With years of dedicated service and a large, loyal customer base, we
            offer the best prices for tempo traveller rentals in Pondicherry.
            Our high-volume travel rental business enables us to pass on
            significant savings to our customers, making our rates the most
            competitive in the market. Unlike others, we maintain these low
            prices without compromising on the quality of service or the
            condition of our vehicles.
          </p>
        </>
      ),
    },
    {
      title: "Our Extensive Van Rental Service in Pondicherry",
      content: (
        <>
          <p>
            Looking for a reliable and comfortable van rental service in
            Pondicherry? We offer a wide range of well-maintained vans to cater
            to your transportation needs, whether you're traveling solo, with
            family, or in a large group. Our fleet includes spacious and modern
            vehicles designed for both short and long-distance travel. Whether
            you need a compact van for a small group or a larger one for bigger
            gatherings, we have options to suit all your requirements.Our fleet
            includes: 12-seater vans,14-seater vans, 16-seater vans,Luxury vans
            for a more comfortable experience. All our vans undergo regular
            maintenance checks to ensure they are in top condition. We
            prioritize your safety with features like seat belts, air
            conditioning, and GPS systems for smooth and safe travel.Renting a
            van from us is simple and quick. You can book online or by calling
            our customer service team. We also offer flexible payment options
            for your convenience.
          </p>
        </>
      ),
    },
    {
      title: "Have a happy trip from Pondicherry to Tirupathi traveller trip",
      content: (
        <p>
          Are you planning a trip from Pondicherry to the spiritual city of
          Tirupati? Travel in comfort, style, and convenience with our Traveller
          Rental Service. Whether you’re heading to the famous Tirumala
          Venkateswara Temple for a pilgrimage, or exploring the city's rich
          culture,our comfortable tempo travellers are the perfect choice for
          your journey. Our vehicles are designed for long journeys, ensuring a
          relaxing and comfortable ride for all passengers.
        </p>
      ),
    },
    {
      title: "3 Nights, 4 Days Aarupadai Veedu Tour from Pondicherry",
      content: (
        <p>
          Embark on a spiritual journey to the Aarupadai Veedu (Six Abodes of
          Lord Murugan) with our carefully curated 3 Nights, 4 Days tour
          package. This package offers a chance to explore the six holy temples
          dedicated to Lord Murugan, located across Tamil Nadu. Each temple has
          its unique significance and attracts devotees from around the world.
        </p>
      ),
    },
  ];
  return (
    <>
      <div className="rental-package container py-5">
        <h2 className="rental-title text-center mb-4 mt-5">Van Rental</h2>
        <h3 className="sub-title-tariff">Tariff</h3>
        <div className="van-tariff-table-container">
          <table className="table van-tariff-table table-bordered table-hover">
            <thead className="bg-primary text-white">
              <tr>
                <th className="text-center">Van Type</th>
                <th className="text-center">Seaters</th>
                <th className="text-center">5 Hrs/ 50 Kms</th>
                <th className="text-center">10 Hrs/ 100 Kms</th>
                <th className="text-center">15 Hrs/ 150 Kms</th>
                <th className="text-center">Additional Kms</th>
                <th className="text-center">Additional Hrs</th>
                <th className="text-center">Outstation Min. 250KM</th>
                <th className="text-center">Outstation Add Per KM</th>
                <th className="text-center">Driver Batta/Day</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Luxury Tempo Traveller</td>
                <td className="text-center">9</td>
                <td className="text-center">₹3,000.00</td>
                <td className="text-center">₹6,000.00</td>
                <td className="text-center">₹9,000.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹750.00</td>
                <td className="text-center">₹5,500.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹700.00</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="text-center">Premium Tempo Traveller</td>
                <td className="text-center">12</td>
                <td className="text-center">₹3,000.00</td>
                <td className="text-center">₹6,000.00</td>
                <td className="text-center">₹9,000.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹750.00</td>
                <td className="text-center">₹5,500.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹700.00</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="text-center">Elite Tempo Traveller</td>
                <td className="text-center">16</td>
                <td className="text-center">₹3,000.00</td>
                <td className="text-center">₹6,000.00</td>
                <td className="text-center">₹9,000.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹750.00</td>
                <td className="text-center">₹5,500.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹700.00</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="text-center">Seater Tempo Traveller</td>
                <td className="text-center">26</td>
                <td className="text-center">₹3,000.00</td>
                <td className="text-center">₹6,000.00</td>
                <td className="text-center">₹9,000.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹750.00</td>
                <td className="text-center">₹5,500.00</td>
                <td className="text-center">₹22.00</td>
                <td className="text-center">₹700.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row car-rental">
          {sections.map((section, index) => (
            <div className="row align-items-center mb-4" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/van-traveller.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/van-traveller.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VanRental;
