import React from "react";

export default function BusTariff() {
  return (
    <div className="bus-tariff-section">
      <h2 className="bus-tariff-title text-center mb-4">Bus Rental Tariff</h2>
      <div className="bus-tariff-table-container">
        <table className="table bus-tariff-table table-bordered table-hover">
          <thead className="bus-tariff-header bg-primary text-white">
            <tr>
              <th className="text-center">Bus Type</th>
              <th className="text-center">Seaters</th>
              <th className="text-center">5 Hrs/ 50 Kms</th>
              <th className="text-center">10 Hrs/ 100 Kms</th>
              <th className="text-center">Additional Kms</th>
              <th className="text-center">Additional Hrs</th>
              <th className="text-center">Outstation Min. 300KM</th>
              <th className="text-center">Outstation Add Per KM</th>
              <th className="text-center">Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">SML Executive Seats A/C</td>
              <td className="text-center">28</td>
              <td className="text-center">₹3,500.00</td>
              <td className="text-center">₹5,000.00</td>
              <td className="text-center">40.00</td>
              <td className="text-center">₹800.00</td>
              <td className="text-center">₹12000.00</td>
              <td className="text-center">₹40.00</td>
              <td className="text-center">₹750.00</td>
            </tr>
            <tr>
              <td className="text-center">SML Executive Seats Non A/C</td>
              <td className="text-center">28</td>
              <td className="text-center">₹3,000.00</td>
              <td className="text-center">₹4,000.00</td>
              <td className="text-center">₹35.00</td>
              <td className="text-center">₹800.00</td>
              <td className="text-center">₹11500.00</td>
              <td className="text-center">₹35.00</td>
              <td className="text-center">₹750.00</td>
            </tr>
          </tbody>
        </table>
        *Including Fuel and Excluding Toll, Parking and Permit Charges.
      </div>

      <div className="bus-tariff-table-container mt-4">
        <table className="table bus-tariff-table table-bordered table-hover">
          <thead className="bus-tariff-header bg-primary text-white">
            <tr>
              <th className="text-center">Bus Type</th>
              <th className="text-center">Seaters</th>
              <th className="text-center">Rent (max. 300KM)</th>
              <th className="text-center">Additional Kms</th>
              <th className="text-center">Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">SML Executive Seats A/C</td>
              <td className="text-center">28</td>
              <td className="text-center">₹6,500.00</td>
              <td className="text-center">40.00</td>
              <td className="text-center">₹750.00</td>
            </tr>
            <tr>
              <td className="text-center">SML Executive Seats Non A/C</td>
              <td className="text-center">28</td>
              <td className="text-center">₹5,500.00</td>
              <td className="text-center">₹35.00</td>
              <td className="text-center">₹750.00</td>
            </tr>
          </tbody>
        </table>
        *Excluding Fuel, Toll, Parking and Permit Charges.
      </div>
    </div>
  );
}
