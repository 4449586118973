import React from 'react';


const Tirupathi = () => {

  return (
    <>
      <div className="container my-5">
        <div className="tirupathi-content">
          <div className="tirupathi-image-container">
            <img
              src="assets/img/blog/tirupathi.png"
              alt="tirupathi Temple"
              className="tirupathifull-width-image"
            />
            <div className='tirupathi-image-content'>
              <h2> 2 Days 1 Night Tirupathi Tour Packages from Pondicherry</h2>
              <p>
                Travels Offers Pondicherry to Tirupati Package by Volvo Bus and We have running AC Sleeper Bus,
                The bus tour running from Pondicherry to Tirupati.
                Please call us  <strong> +91 7010752755, +91 9894 261 300
                </strong> </p>
            </div>
          </div>
          <div className="tirupathi-section">
            <p>
              Pilgrimage Tour Packages
            </p>

            <h2>Pondicherry to Tirupathi Trip Includes:  </h2>
            <ul >
              <li>The Tirupati Package operating AC Bus </li>
              <li> Includes Tollgate, Car Parking, Driver Batta, Andhra Permit Tax & Diesel Charges</li>
              <li>Visit Tirumala Balaji Temple & Padmavathi Temple</li>
              <li>Rs 300 Special Entry Darshan for Lord Balaji, Breakfast & Lunch</li>
              <li>One Laddu Per Head</li>
            </ul>
          </div>
          <div className="tirupathi-section">
            <h2>Pondicherry to Tirupathi Tour Programes:   </h2>
            <ul >
              <li>Start the Journey - Pondicherry to Tirupati by A/C Volvo Bus</li>
              <li>Reaching to Tirupati, Visits Padmavathi Temple in Thiruchanoor</li>
              <li>Reaching to Tirumala Balaji Temple and Our tour guide darshan counter, tonsure (Mottai), Thulaparam and etc</li>
              <li> Lord Venkateswara Swamy Darshan</li>
              <li>Return journey start to Pondicherry</li>
              <li>Drop Pondicherry</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="wp-call-button-container">
        <a
          className="wp-call-button"
          href="tel:+917010752755"
          style={{
            background: "url(/assets/img/phone-call.png) center/30px 30px no-repeat #1d8f26",
            display: "block",
            position: "fixed",
            textDecoration: "none",
            zIndex: 9999999999,
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            right: "40px",
            marginLeft: "-30px",
            bottom: "20px",
          }}
        >
        </a>
      </div>
    </>
  );
};

export default Tirupathi;