import React from "react";
import Counter from "../components/counter/Counter";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgTwo from "../components/features/FeatureImgTwo";
import ExploreContent from "../components/explore/ExploreContent";

export default function LandingPage() {
  return (
    <>
      <ExploreContent />
      <Counter />
      <FeatureImgTwo />
      <FeatureImg ImgSource="assets/img/happy_client2.png" />
    </>
  );
}
