import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Navbar from "../components/layout/header/Navbar";
import Footer from "../components/layout/footer/Footer"
import ScrollToTop from "../components/scroll/ScrollToTop";
import Tariff from "../pages/Tariff";
import CarRental from "../components/rentals/CarRental";
import VanRental from "../components/rentals/VanRental";
import BusRental from "../components/rentals/BusRental";
import LandingPage from "../pages/LandingPage"
import Gallery from "../pages/Gallery";
import Tirupathi from "../components/packages/Tirupathi";
import Aarupadai from "../components/packages/Aarupadai";
import TermsAndConditions from "../components/others/TermsandCondtion";
import PrivacyPolicy from "../components/others/PrivecyPolicy";
import FeatureImgTwo from "../components/features/FeatureImgTwo";
import Contact from "../pages/Contact";
import About from "../pages/About";

const AppRoutes = () => {
  return (
    <Layout>
      <Navbar classOption="custom-header" />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/FeatureImgTwo" element={<FeatureImgTwo />} />
        <Route path="/about" element={<About />} />
        <Route path="/tariff" element={<Tariff />} />
        <Route path="/car-rental" element={<CarRental />} />
        <Route path="/bus-rental" element={<BusRental />} />
        <Route path="/van-rental" element={<VanRental />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/tirupathi" element={<Tirupathi />} />
        <Route path="/aarupadai" element={<Aarupadai />} />
        <Route path="/privecy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndConditions />} />
      </Routes>
      <Footer />
    </Layout>
  );
};

export default AppRoutes;
