import React from "react";

const BusRental = () => {
  const sections = [
    {
      title: "Tourist Bus Rental in Chennai",
      content: (
        <>
          <p>
            Tourist Buses are widely spacious and luxurious in general and only
            Tourist Bus Rentals are comfortable for long-distance travel and
            prolonged journeys. Chennai Travels is successful and competent
            enough in offering such sophisticated travel experiences to the
            customers across the country and you can enjoy Tourist Bus Rental at
            the cheap and best rate. Tourist Bus Rentals in Chennai are well
            maintained and no sounds from outside will be heard while traveling
            and travel will be smooth enough for sure. More than 50 persons can
            be seated comfortably and these types of buses are preferred for
            long trips to Temples and other outstations.
          </p>
        </>
      ),
    },
    {
      title: "Extensive Bus Rental Service for Tourism(AC & Non AC)",
      subtitle: "Bus Rental for Temple Tours",
      content: (
        <>
          <p>
            Temple tours are widely offered by Chennai Travels and you will be
            able to visit the important temples all over the country. For
            special occasions, destined darshans will be performed and done at
            various temples. If the journey date is preferred in the correct
            dates you can have those darshans for sure. For temple tours, a
            group of people is preferred who is willing to have journeyed at the
            same time and processing the same schedule. You can have both AC and
            non AC provisions all through the travel. AC Bus Rentals are
            inclusive of main charges.
          </p>
          <ul>
            <li>
              <a href="#">Sabarimala Tour Packages</a>
            </li>
            <li>
              <a href="#">Rameshwaram Tour Packages</a>
            </li>
            <li>
              <a href="#">Velankanni Tour Packages</a>
            </li>
            <li>
              <a href="#">Tirupati Tour Package</a>
            </li>
            <li>
              <a href="#">Thirunallar Tour Package</a>
            </li>
            <li>
              <a href="#">Navagraha Temple Tour Package</a>
            </li>
            <li>
              <a href="#">Srisailam Tour Packages</a>
            </li>
            <li>
              <a href="#">Kumbakonam Temple Tour Package</a>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Bus Rental for Holiday Trips",
      content: (
        <p>
          We do offer holiday trips and vocational tours across the country with
          specially designed buses. Those types of specialized buses are made
          with a speed braking system and airbag provisions. All the doors are
          specified with child locks and other safety enhancements. This package
          covers all trips to hill stations and other main tourist spots. Here
          is the list of popular leisure/holiday tour packages:
          <ul>
            <li>
              <a href="#">Holiday Tour Package</a>
            </li>
            <li>
              <a href="#">South India Tours</a>
            </li>
            <li>
              <a href="#">Kodaikanal Tour Package</a>
            </li>
            <li>
              <a href="#">Weekend Tour</a>
            </li>
            <li>
              <a href="#">Pondicherry Family Tour Package</a>
            </li>
            <li>
              <a href="#">Chennai Mahabalipuram Tour Packages</a>
            </li>
            <li>
              <a href="#">Srisailam Tour Packages</a>
            </li>
            <li>
              <a href="#">Kumbakonam Temple Tour Package</a>
            </li>
          </ul>
        </p>
      ),
    },
    {
      title: "Types of Affordable Buses on Rent:",
      content: (
        <p>
          Bus travels are extremely cheap and the best ones and you can have a
          safe travel too. Our drivers are professionally trained and well
          experienced with these types of long trips and tours. Cent percentage
          of safety is assured from our side. We will let you know the travel
          details before the start of the journey and doorstep pick up and drop
          is assured. Below are some of our important buses available for rent
          at affordable price ranges
        </p>
      ),
    },
    {
      title: " 25 and 45 Seater",
      content: (
        <p>
          5 seater buses are available with two-seaters on both sides with
          convenient cushion provisions. Seats will be highly comfortable and
          Air conditioning systems are installed in the buses to have a
          memorable travel experience. 45 seaters and more seater buses are
          available with a three-plus two-seat combo and those buses will be
          highly spacious and push back seats are designed here for relaxation.
          These forms of buses are the reliable ones and most commonly preferred
          ones
        </p>
      ),
    },
    {
      title: "Mini Bus 21 and 25 Seater",
      content: (
        <p>
          Mini Bus Rental is preferred for a large family or neighbors to have
          long trips. If people are not sufficient enough to accommodate the
          entire bus, Minibusses are the ideal choice and those buses are
          available with and without AC and you can prefer your own choice of
          option. Al types of minibusses (21 Seater & 25 Seater) are available
          with two-plus two-seaters with cushion and push back options. AC buses
          are an ideal choice for long journeys and you can carry heavy luggage
          on the vehicle for sure.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="rental-package container py-5">
        <h2 className="rental-title text-center mb-4 mt-5">Bus Rental</h2>
        <h3 className="sub-title-tariff">Tariff</h3>
        <div className="bus-tariff-table-container">
          <table className="table bus-tariff-table table-bordered table-hover">
            <thead className="bus-tariff-header bg-primary text-white">
              <tr>
                <th className="text-center">Bus Type</th>
                <th className="text-center">Seaters</th>
                <th className="text-center">5 Hrs/ 50 Kms</th>
                <th className="text-center">10 Hrs/ 100 Kms</th>
                <th className="text-center">Additional Kms</th>
                <th className="text-center">Additional Hrs</th>
                <th className="text-center">Outstation Min. 300KM</th>
                <th className="text-center">Outstation Add Per KM</th>
                <th className="text-center">Driver Batta/Day</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">SML Executive Seats A/C</td>
                <td className="text-center">28</td>
                <td className="text-center">₹3,500.00</td>
                <td className="text-center">₹5,000.00</td>
                <td className="text-center">40.00</td>
                <td className="text-center">₹800.00</td>
                <td className="text-center">₹12000.00</td>
                <td className="text-center">₹40.00</td>
                <td className="text-center">₹750.00</td>
              </tr>
              <tr>
                <td className="text-center">SML Executive Seats Non A/C</td>
                <td className="text-center">28</td>
                <td className="text-center">₹3,000.00</td>
                <td className="text-center">₹4,000.00</td>
                <td className="text-center">₹35.00</td>
                <td className="text-center">₹800.00</td>
                <td className="text-center">₹11500.00</td>
                <td className="text-center">₹35.00</td>
                <td className="text-center">₹750.00</td>
              </tr>
            </tbody>
          </table>
          *Including Fuel and Excluding Toll, Parking and Permit Charges.
        </div>
        <div className="bus-tariff-table-container mt-4">
          <table className="table bus-tariff-table table-bordered table-hover">
            <thead className="bus-tariff-header bg-primary text-white">
              <tr>
                <th className="text-center">Bus Type</th>
                <th className="text-center">Seaters</th>
                <th className="text-center">Rent (max. 300KM)</th>
                <th className="text-center">Additional Kms</th>
                <th className="text-center">Driver Batta/Day</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">SML Executive Seats A/C</td>
                <td className="text-center">28</td>
                <td className="text-center">₹6,500.00</td>
                <td className="text-center">40.00</td>
                <td className="text-center">₹750.00</td>
              </tr>
              <tr>
                <td className="text-center">SML Executive Seats Non A/C</td>
                <td className="text-center">28</td>
                <td className="text-center">₹5,500.00</td>
                <td className="text-center">₹35.00</td>
                <td className="text-center">₹750.00</td>
              </tr>
            </tbody>
          </table>
          *Excluding Fuel, Toll, Parking and Permit Charges.
        </div>
        <div className="row mt-4">
          {sections.map((section, index) => (
            <div className="row align-items-center mb-4" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/sml-bus.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/tourist-bus.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BusRental;
