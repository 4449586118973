export default function HeroEight() {
  return (
    <>
      <section className="hero-section hero-section-3 ptb-100">
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>
        </div>
        <div className="container" id="animation-area-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6 home-content">
              <div className="hero-content section-title text-center text-lg-left my-5 mt-lg-0">
                <span className="text-uppercase color-secondary font-weight-bold">
                  Explore the world
                </span>
                <h1 className="font-weight-bold">
                   Discover the World’s Wonders with Us
                </h1>
                <p className="lead">
                "Your next adventure starts here! Discover beautiful places,
                 from hidden gems to popular destinations, with our exclusive travel packages."{" "}
                </p>
                <div className="action-btn mt-4">
                  <a href="#/" className="btn solid-btn">
                    Get our Services
                  </a>
                </div>
              </div>
            </div>
            <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end home-content">
              <div className="hero-img position-relative">
                <div className="image-bg-positioning">
                  <img
                    src="assets/img/small-sml.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
