import React from "react";

const images = [
  "/assets/img/gallery/sml-bus1.png",
  "/assets/img/gallery/sml-bus2.png",
  "/assets/img/gallery/sml-bus3.png",
  "/assets/img/gallery/innova.jpg",
  "/assets/img/gallery/innova2.jpg",
  "/assets/img/gallery/single-bus.jpg",
];

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="section-heading text-center">
            <span className="text-uppercase color-secondary sub-title">
              Gallery Showcase
            </span>
            <h2>Explore Our Stunning Gallery</h2>
            <p className="lead">
              Discover captivating visuals that showcase the essence of our
              experiences. Dive into our gallery and get a glimpse of the
              breathtaking moments we capture.
            </p>
          </div>
        </div>
      </div>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
