import React from "react";
import CarTariff from "../components/tariff/CarTariff";
import VanTariff from "../components/tariff/VanTariff";
import BusTariff from "../components/tariff/BusTariff";

export default function Tariff() {
  return (
      <div className="login-bottom-spacing">
        <section className="tariff-screen-section ptb-300 full-screen">
          <div className="container">
            <CarTariff />
            <VanTariff />
            <BusTariff />
            <h3 className="tariff-terms-title text-center mt-5">Tariff Terms and Conditions</h3>
            <ul className="tariff-terms-list">
              <li>
                <b>Distance Calculation:</b> Starts from our garage to your destination.
              </li>
              <li>
                <b>Hourly Rates:</b> More than 7 hours equals 10 hours; more than 12 hours equals 15 hours.
              </li>
              <li>
                <b>Included Costs:</b> Rates cover fuel and driver’s service only.
              </li>
              <li>
                <b>Additional Fees:</b> Tolls, parking, and entry fees are charged separately.
              </li>
              <li>
                <b>Drive Batta:</b> Calculated on a calendar day basis.
              </li>
              <li>
                <b>Rate Changes:</b> Rates may fluctuate with fuel prices. Confirm before booking.
              </li>
              <li>
                <b>Cancellation Policy:</b> Charges apply if the vehicle departs or for no-shows.
              </li>
              <li>
                <b>Availability:</b> Listed models are subject to availability.
              </li>
              <li>
                <b>Advance Payment:</b> 50% required to book a vehicle.
              </li>
            </ul>
          </div>
        </section>
      </div>
  );
}
