import React from "react";

export default function CarTariff() {
  return (
    <div className="car-tariff-section">
      <h2 className="car-tariff-title text-center mt-5">Car Rental Tariff</h2>
      <div className="car-tariff-table-container">
        <table className="table car-tariff-table table-bordered table-hover">
          <thead className="car-tariff-header bg-primary text-white">
            <tr>
              <th className="text-center">Car Type</th>
              <th className="text-center">Seaters</th>
              <th className="text-center">4 Hrs/ 40 Kms</th>
              <th className="text-center">8 Hrs/ 80 Kms</th>
              <th className="text-center">Additional Kms</th>
              <th className="text-center">Additional Hrs</th>
              <th className="text-center">Outstation Min. 250KM</th>
              <th className="text-center">Outstation Add Per KM</th>
              <th className="text-center">Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">Toyota Etios</td>
              <td className="text-center">4+1</td>
              <td className="text-center">₹1,200.00</td>
              <td className="text-center">₹2,400.00</td>
              <td className="text-center">₹13.00</td>
              <td className="text-center">₹220.00</td>
              <td className="text-center">₹3,250.00</td>
              <td className="text-center">₹13.00</td>
              <td className="text-center">₹600.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
