import React from "react";

const CarRental = () => {
  const sections = [
    {
      title: "Toyota Glanza Car Rental",
      content: (
        <>
          <p>
            Our car rental service in Pondicherry is successful. This is because
            of the outstanding and truthful service that we at Pondicherry
            Travels, since time never-ending. So, our customers can enjoy their
            travel to the maximum by getting a rental car in Pondicherry.
          </p>
          <p>
            We at Pondicherry Travels make sure that your trip a luxurious one.
            Thus you can travel around to the various parts of Pondicherry with
            an open occasion to view the exotic natural treasures of
            Pondicherry.
          </p>
          <p>
            We are offering car rental services with drivers for business trips
            and other vacation trips all over the places from Pondicherry. Car
            for Rental in Pondicherry is also available with proper placement of
            drivers. We are offering these services with properly maintained and
            conditioned vehicles with A/C provisions.
          </p>
        </>
      ),
    },
    {
      title: "Our Extensive Car Rental Service in Pondicherry",
      subtitle: "Luxury Cars For Rent",
      content: (
        <>
          <p>
            Pondicherry Travels makes corporate car rentals easy with a great
            selection of vehicles, perfect for any company need. Whether it’s
            for meetings or client visits, we’ve got you covered with flexible
            bookings and top-notch service!
          </p>
        </>
      ),
    },
    {
      title: "Wedding Cars for Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Pondicherry. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
    {
      title: "Monthly Car Rental",
      content: (
        <p>
          At Pondicherry Travels we provide cars for monthly rentals with
          drivers if you wish. At sometimes, cars have to be hired for
          occasional plans and regular corporate trips and events. You can save
          a certain amount of money if you tend to prefer monthly rental
          packages.
        </p>
      ),
    },
    {
      title: "Employee Car Rental Service",
      content: (
        <p>
          The cheapest Car Rentals in Pondicherry are accessible and available
          for employee transportations. Employees include both private and
          government sectors. Car rental in Pondicherry is made stress-free
          through us.
        </p>
      ),
    },
    {
      title: "Luxury Cars For Rent",
      content: (
        <p>
          Our services are designed to meet your budget without compromising on
          quality or style. We offer beautifully crafted floral arrangements
          tailored to your preferences, creating a car that matches your wedding
          theme. Whether you prefer a classic, vintage, or modern car, we ensure
          it’s decorated and prepared just the way you want.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="rental-package container py-5">
        <h2 className="rental-title text-center mb-4 mt-5">Car Rental</h2>
        <h3 className="sub-title-tariff">Tariff</h3>
        <div className="car-tariff-table-container">
          <table className="table car-tariff-table table-bordered table-hover">
            <thead className="car-tariff-header bg-primary text-white">
              <tr>
                <th className="text-center">Car Type</th>
                <th className="text-center">Seaters</th>
                <th className="text-center">4 Hrs/ 40 Kms</th>
                <th className="text-center">8 Hrs/ 80 Kms</th>
                <th className="text-center">Additional Kms</th>
                <th className="text-center">Additional Hrs</th>
                <th className="text-center">Outstation Min. 250KM</th>
                <th className="text-center">Outstation Add Per KM</th>
                <th className="text-center">Driver Batta/Day</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Toyota Etios</td>
                <td className="text-center">4+1</td>
                <td className="text-center">₹1,200.00</td>
                <td className="text-center">₹2,400.00</td>
                <td className="text-center">₹13.00</td>
                <td className="text-center">₹220.00</td>
                <td className="text-center">₹3,250.00</td>
                <td className="text-center">₹13.00</td>
                <td className="text-center">₹600.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row car-rental">
          {sections.map((section, index) => (
            <div className="row align-items-center mb-4" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/renault-kwid.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src="assets/img/blog/maruti-swift.png"
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CarRental;
