import React from "react";

export default function VanTariff() {
  return (
    <div className="van-tariff-section">
      <h2 className="van-tariff-title text-center mb-4 ">Van Rental Tariff</h2>
      <div className="van-tariff-table-container">
        <table className="table van-tariff-table table-bordered table-hover">
          <thead className="bg-primary text-white">
            <tr>
              <th className="text-center">Van Type</th>
              <th className="text-center">Seaters</th>
              <th className="text-center">5 Hrs/ 50 Kms</th>
              <th className="text-center">10 Hrs/ 100 Kms</th>
              <th className="text-center">15 Hrs/ 150 Kms</th>
              <th className="text-center">Additional Kms</th>
              <th className="text-center">Additional Hrs</th>
              <th className="text-center">Outstation Min. 250KM</th>
              <th className="text-center">Outstation Add Per KM</th>
              <th className="text-center">Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">Luxury Tempo</td>
              <td className="text-center">14</td>
              <td className="text-center">₹3,000.00</td>
              <td className="text-center">₹6,000.00</td>
              <td className="text-center">₹9,000.00</td>
              <td className="text-center">₹22.00</td>
              <td className="text-center">₹750.00</td>
              <td className="text-center">₹5,500.00</td>
              <td className="text-center">₹22.00</td>
              <td className="text-center">₹700.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
