import React from 'react';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';


const Aarupadai = () => {
  const itinerary = [
    {
      day: 'Pondicherry to Swamimalai-Tiruchendur',
      image: "assets/img/blog/pondicherry.jpg",
      image2: "assets/img/blog/swamimalai.jpg",
      activities: [
        { activity: 'Today Morning 6.00 AM Pondicherry Pickup to Swamiamlai, take a travel 3 hours, reach at Swamiamlai darshan to Fourth Abode Among the Arupadai Veedu Of Lord Murugan temple after Darshan Proceed to Tiruchendur, reach at Tiruchendur Check in to hotel and Leisure.- Night Stay at Tiruchendur' },
      ],
    },
    {
      day: 'Tiruchendur-Thiruparankundram-Pazhamudircholai-Palani',
      image: "assets/img/blog/thiruchendur.jpg",
      image2: "assets/img/blog/Pazhamudircholai.jpg",
      activities: [
        { activity: 'Today after Breakfast Darshan to Fifth Abode among the Arupadai Veedu of Tiruchendur Murugan temple after Darshan Checkout from the hotel Proceed to Thiruparankundram, reach at Thiruparankundram Darshan to First Abode Among the Arupadai Veedu Of God Murugan temple after Darshan Start Your Journey Pazhamudhircholai, One hour Drive reach at Pazhamudhircholai Darshan to Sixth Abode among the Arupadai Veedu Of Murugan temple after Darshan Proceed to Palani Overnight hotel transfer.- Night Stay at Palani' },
      ],
    },
    {
      day: 'Palani to Thiruthani',
      image: "assets/img/blog/palani.jpg",
      image2: "assets/img/blog/tiruthani.jpg",
      activities: [
        { activity: 'Today Morning Breakfast at Hotel after Breakfast Check out from the hotel start to drive Third Abode among the Arupadai Veedu of Murugan temple after Darshan Proceed to Thiruthani, Reach at Thiruthani Check in to hotel and Leisure.- Night Stay at Thiruthani' },
      ],
    },
    {
      day: 'Thiruthani-Pondicherry Drop',
      image: "assets/img/blog/tiruthani.jpg",
      image2: "assets/img/blog/pondicherry.jpg",
      activities: [
        { activity: 'Today Morning after breakfast Check out from the hotel Start to drive Thiruthani Darshan to Fifth Abode Among the Arupadai Veedu Of Lord Murugan temple after Darshan Proceed to Pondicherry Drop' },
      ],
    },
  ];

  return (
    <>

      <div className="container my-5">
        <div className="murugan-content">
          <div className="murugan-image-container">
            <img
              src="assets/img/blog/tiruthani.jpg"
              alt="Murugan Temple"
              className="muruganfull-width-image"
            />
            <div className='murugan-image-content'>
              <h2>4 Days 3 Nights Aarupadai Veedu Tour Packages from Pondicherry</h2>
              <p>
                Welcome to Arupadai Veedu Tour Package from Pondicherry.
                We Offer Arupadai Veedu Tour Package Pondicherry Arranged by Road variety of Individual Vehicle with Accomodation at Reasonable Price.
                For Booking Arupadai Veedu Murugan temple Tour Package from Pondicherry.
                Please call us or <strong> Whatsapp No: +91 7010752755, +91 9894 261 300</strong> </p>
            </div>
          </div>
          <div className="murugan-section">
            <h2>Arupadai Veedu Temple Destination:  </h2>
            <p>Swamimalai-Tiruchednur-Thiruparankundram-Pazhamudirchoolai-Palani-Thiruthani</p>
            <h2>Duration:   </h2>
            <p>03 Nights  / 04 Days</p>
            <h2>Package Includes:- </h2>
            <ul >
              <li>Car Includes: Fuel, Driver Batta, Tollgate, and Parking.</li>
              <li>03 Nights Stay @ AC Accommodation on Double.</li>
              <li>Budget Package: AC room only</li>
              <li>Standard Package: Complimentary breakfast at hotel</li>
            </ul>
            <p>
              Whatever may be the date & number of persons, book a luxurious
              journey with our exclusive Aarupadai tour packages from Pondicherry.
            </p>
          </div>
        </div>
        {itinerary.map((dayPlan, index) => (
          <Card key={index} className="mb-4 shadow-sm">
            <Card.Header>
            <div className="plan-header">{dayPlan.day}</div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center mb-3 mb-md-0">
                  <img
                    src={dayPlan.image}
                    alt={`Image for ${dayPlan.day}`}
                    className="img-fluid rounded"
                    style={{ objectFit: 'cover', }}
                  />
                </Col>

                <Col xs={12} md={4}>
                  
                  <ListGroup variant="flush">
                    {dayPlan.activities.map((item, idx) => (
                      <ListGroup.Item
                        key={idx}
                        className="d-flex justify-content-between align-items-start"
                      >
                        <span className='text-justify'>{item.activity}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>

                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center mb-3 mb-md-0">
                  <img
                    src={dayPlan.image2}
                    alt={`Second image for ${dayPlan.day}`}
                    className="img-fluid rounded w-100"
                    style={{ objectFit: 'cover' }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="wp-call-button-container">
        <a
          className="wp-call-button"
          href="tel:+917010752755"
          style={{
            background: "url(/assets/img/phone-call.png) center/30px 30px no-repeat #1d8f26",
            display: "block",
            position: "fixed",
            textDecoration: "none",
            zIndex: 9999999999,
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            left: "50%",
            marginLeft: "-30px",
            bottom: "20px",
          }}
        >
        </a>
      </div>

    </>
  );
};

export default Aarupadai;